import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import global_en from "../src/translation/en/global.json"
import global_al from "../src/translation/al/global.json"
import global_mk from "../src/translation/mk/global.json"
import i18next from "i18next"
import { I18nextProvider } from 'react-i18next';

i18next.init({
    interpolation :{escapeValue:true},
    lng:"en",
    resources:{
        en:{
            global: global_en,
        },
        al:{
            global : global_al,
        },
        mk:{
            global : global_mk,
        }
    },
})


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
