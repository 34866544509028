import React, { useState, useEffect } from "react";
import CaliforniAd from "../../assets/videos/californiaadd.mp4";
import { useInView } from "react-intersection-observer";
import { useTranslation } from "react-i18next";
import NavBar from "../NavBar/NavBar";

const ImageCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [ref, inView] = useInView({
    threshold: 0.7,
  });
  const slides = [CaliforniAd];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 5000); // Added a delay for the interval

    return () => clearInterval(interval); // Clean up the interval
  }, [slides.length]);

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [inView]);

  const [t] = useTranslation("global");

  return (
    <div ref={ref} className="relative w-full h-screen overflow-hidden">
      {slides.map((slide, index) => (
        <div
          key={index}
          className="absolute top-0 left-0 w-full h-full"
          style={{
            opacity: index === currentIndex ? 1 : 0,
            transition: "opacity 1s ease-in-out",
          }}
        >
          <video
            playsInline
            autoPlay
            muted
            loop
            className="absolute top-0 left-0 w-full h-full object-cover"
          >
            <source src={slide} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50">
            <div className="text-primaryYellow text-center">
              <h1
                style={{ fontFamily: "Comfortaa" }}
                className={`uppercase space-x-0 font-black text-opacity-90 text-5xl transition-all duration-1000 ${
                  isVisible
                    ? "translate-y-0 opacity-100"
                    : "translate-y-10 opacity-0"
                }`}
              >
                {t("ImageCarousel.title")}
              </h1>
              <p
                style={{ fontFamily: "Comfortaa" }}
                className={`text-2xl text-white mt-8 mx-auto text-center font-light w-[60%] italic transition-all duration-[4000ms] ${
                  isVisible
                    ? "translate-x-0 opacity-100"
                    : "lg:translate-x-10 opacity-0"
                }`}
              >
                {t("ImageCarousel.quote")}
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ImageCarousel;
