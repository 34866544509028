import React from 'react'

const simplepricingcard = (props) => {
  return (
    <div className="flex flex-col items-center align-middle bg-zinc-900 py-2 px-6 w-96 rounded-xl border-t-[3px] border-solid border-1 border-primaryYellow">
      <h1
        style={{ fontFamily: "Comfortaa" }}
        className="w-full text-center p-4 text-white text-2xl"
      >
        {props.title}
      </h1>
      <div className="w-full flex flex-row justify-center">
        <div className=" mx-auto text-white w-[70%] m-0 text-5xl font-normal  block  text-right">
          {props.offer1}
        </div>
        <div className='w-[40%] mt-7'
        style={{ fontFamily: "Comfortaa" }}>
            {props.offer2}
        </div>
      </div>
      <div className='h-[20%] mt-4 mb-0'
      style={{ fontFamily: "Comfortaa" }}>
      <i>+ Body Analysis</i>
      </div>
      <a
        style={{ fontFamily: "Comfortaa" }}
        href="/contact"
        className="inline-block px-4 py-2 border border-white text-white rounded-md text-center 
                        transition duration-300 transform hover:bg-white hover:text-black hover:shadow-md hover:scale-105 mt-6 mb-6"
      >
        {props.button_txt}
      </a>
    </div>
  )
}

export default simplepricingcard