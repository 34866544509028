import React, { useState, useEffect } from "react";
import MembershipBanner from "../../assets/images/pricing-banner.jpeg";
import SimplePricingCard from "./simplepricingcard";
import { useInView } from "react-intersection-observer";
import { useTranslation } from "react-i18next";

const Memberships = () => {
  const [t] = useTranslation("global");
  const [activeTab, setActiveTab] = useState("woman");
  const [isVisible, setIsVisible] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0.5, // Trigger when 50% of the component is visible
  });

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [inView]); // Run only when 'inView' changes

  return (
    <div ref={ref} className="flex flex-col justify-center items-center mb-32 ">
      <div
        className="w-full h-auto relative bg-opacity-0 items-center"
        style={{
          backgroundImage: `linear-gradient(rgba(10, 10, 10, .90), rgba(10, 10, 10, .90)), url(${MembershipBanner})`,
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="max-w-[1280px] mx-auto p-6">
          <div className="flex flex-col items-center space-y-6 mt-20">
            <h1
              style={{ fontFamily: "Comfortaa" }}
              className="text-4xl text-white font-bold text-center"
            >
              {t("Memberships.title")}
            </h1>
            <p
              style={{ fontFamily: "Comfortaa" }}
              className="text-xl text-white text-center"
            >
              {t("Memberships.description")}
            </p>
            <div className="w-[2px] h-20 bg-white"></div>
          </div>
          <div className="flex justify-center space-x-8 my-8">
            <h4
              style={{ fontFamily: "Comfortaa" }}
              className={`text-lg text-white pb-1 cursor-pointer ${
                activeTab === "woman" ? "border-b-2 border-white" : ""
              }`}
              onClick={() => setActiveTab("woman")}
            >
              {t("Memberships.woman")}
            </h4>
            <h4
              style={{ fontFamily: "Comfortaa" }}
              className={`text-lg text-white pb-1 cursor-pointer ${
                activeTab === "man" ? "border-b-2 border-white" : ""
              }`}
              onClick={() => setActiveTab("man")}
            >
              {t("Memberships.man")}
            </h4>
          </div>
          <div className="flex flex-wrap justify-center gap-4">
            {activeTab === "woman" && (
              <>
                <SimplePricingCard
                  title={t("Memberships.monthly")}
                  offer1="1200"
                  offer2="MKD"
                  button_txt={t("Memberships.buttonText")}
                />
                <SimplePricingCard
                  title={t("Memberships.3months")}
                  offer1="3000"
                  offer2="MKD"
                  button_txt={t("Memberships.buttonText")}
                />
                <SimplePricingCard
                  title={t("Memberships.12months")}
                  offer1="10000"
                  offer2="MKD"
                  button_txt={t("Memberships.buttonText")}
                />
              </>
            )}
            {activeTab === "man" && (
              <>
                <SimplePricingCard
                  title="Monthly package"
                  offer1="1500"
                  offer2="MKD"
                  button_txt="Join Today"
                />
                <SimplePricingCard
                  title="3 Months package"
                  offer1="4000"
                  offer2="MKD"
                  button_txt="Join Today"
                />
                <SimplePricingCard
                  title="12 Months package"
                  offer1="15000"
                  offer2="MKD"
                  button_txt="Join Today"
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Memberships;
