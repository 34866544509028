import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../../assets/images/CaliforniaFitnessCenterLogo.png";
import Burger from "../../assets/images/navBurgerMenu.png";
import CloseMenu from "../../assets/images/closeMobileMenu.png";
import downArrow from "../../assets/images/down-arrow.png";
import { useTranslation } from "react-i18next";

const NavBar = () => {
  const [t, i18next] = useTranslation("global");
  const handleChangeLanguage = (lang) => {
    i18next.changeLanguage(lang);
    setSelectedLanguage(lang);
    setIsOpen(false);
  };

  const paths = [
    {
      pathway: t("NavBar.Home"),
      link: "/",
    },
    {
      pathway: t("NavBar.AboutUs"),
      link: "/about",
    },
    {
      pathway: t("NavBar.Gallery"),
      link: "/gallery",
    },
    {
      pathway: t("NavBar.Contact"),
      link: "/contact",
    },
  ];

  const [isOpen, setIsOpen] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [hoveredItem, setHoveredItem] = useState(null);

  const handleHover = (index) => {
    setHoveredItem(index);
  };

  const handleLeave = () => {
    setHoveredItem(null);
  };

  const glowStyle = (index) => ({
    color: "#fff",
    textAlign: "center",
    animation: hoveredItem === index ? "glow 2s ease-in-out infinite" : "none",
    WebkitAnimation:
      hoveredItem === index ? "glow 2s ease-in-out infinite" : "none",
  });

  const keyframes = `
  @-webkit-keyframes glow {
    0% {
      text-shadow: 0 0 0 #FEC41B, 0 0 0 #FEC41B, 0 0 0 #FEC41B, 0 0 0 #FEC41B, 0 0 0 #FEC41B, 0 0 0 #FEC41B, 0 0 0 #FEC41B;
    }
    50% {
      text-shadow: 0 0 10px #FEC41B, 0 0 30px #FEC41B, 0 0 40px #FEC41B, 0 0 50px #FEC41B, 0 0 60px #FEC41B, 0 0 70px #FEC41B, 0 0 80px #FEC41B;
    }
    100% {
      text-shadow: 0 0 0 #FEC41B, 0 0 0 #FEC41B, 0 0 0 #FEC41B, 0 0 0 #FEC41B, 0 0 0 #FEC41B, 0 0 0 #FEC41B, 0 0 0 #FEC41B;
    }
  }
`;

  return (
    <div className="flex justify-between items-center border-solid bg-transparent h-20 w-full fixed lg:relative top-0 lg:z-0 z-50 ">
      <NavLink
        className="hidden lg:flex flex-row h-20 w-1/8 p-0 mx-2 justify-center items-center hover:cursor-pointer"
        to="/"
      >
        <img
          className="object-cover h-20 w-40 m-0 p-0"
          src={Logo}
          alt="California Fitness Center Logo"
        />
      </NavLink>

      <ul className="hidden lg:flex flex-row justify-center items-center h-20 w-fit p-0">
        {paths.map((e, index) => (
          <li
            style={{ fontFamily: "Comfortaa" }}
            className="h-full text-white mx-6 px-2.5 pt-7 pb-1 text-xl border-t-2 border-solid border-transparent hover:border-t-primaryYellow hover:border-solid hover:border-spacing-0 group relative"
            key={e.pathway}
          >
            <style>{keyframes}</style>
            <NavLink
              style={glowStyle(index)}
              to={e.link}
              className="transition-all group-hover:text-zinc-500 group-hover:border-primaryYellow group-hover:after:opacity-100 duration-300 ease-in-out"
              onMouseEnter={() => handleHover(index)}
              onMouseLeave={handleLeave}
              onClick={() => setMobileMenu(false)}
            >
              {e.pathway}
              <div className="transition-all absolute top-0 left-0 right-0 h-2 bg-gradient-to-b from-primaryYellow to-transparent opacity-0 group-hover:opacity-100 duration-300 ease-in-out"></div>
            </NavLink>
          </li>
        ))}
      </ul>

      <div className="lg:mr-4 flex flex-row items-center box-border">
        <div className="relative">
          <div
            style={{ fontFamily: "Comfortaa" }}
            className="text-white flex bg-transparent cursor-pointer"
            onClick={() => setIsOpen(!isOpen)}
          >
            {selectedLanguage.toUpperCase()}{" "}
            <img src={downArrow} alt="down arrow" className="w-5 h-5 " />
          </div>
          {isOpen && (
            <div className="absolute lg:right-0   mt-2 py-2 w-20 bg-black bg-opacity-60 rounded-lg shadow-lg z-10">
              <div
                style={{ fontFamily: "Comfortaa" }}
                className="text-white px-4 py-2 hover:bg-secondaryYellow hover:bg-opacity-80 transition-all duration-300 cursor-pointer"
                onClick={() => handleChangeLanguage("en")}
              >
                EN
              </div>
              <div
                style={{ fontFamily: "Comfortaa" }}
                className="text-white px-4 py-2 hover:bg-secondaryYellow hover:bg-opacity-80 transition-all duration-300 cursor-pointer"
                onClick={() => handleChangeLanguage("al")}
              >
                AL
              </div>
              <div
                style={{ fontFamily: "Comfortaa" }}
                className="text-white px-4 py-2 hover:bg-secondaryYellow hover:bg-opacity-80 transition-all duration-300 cursor-pointer"
                onClick={() => handleChangeLanguage("mk")}
              >
                MK
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Mobile Part */}
      <div className=" lg:hidden xl:hidden z-40">
        <img
          src={Burger}
          alt="burger menu"
          className="w-8 h-8 cursor-pointer"
          onClick={() => setMobileMenu(!mobileMenu)}
        />
      </div>

      <div
        className={`fixed top-0 inset-0 bg-black z-40 flex flex-col items-center justify-center transform transition-transform duration-500 ease-in-out h-screen w-screen ${
          mobileMenu ? "translate-y-0" : "-translate-y-full"
        }`}
      >
        <img 
          src={CloseMenu}
          className="absolute top-5 right-5 hover:cursor-pointer w-6 h-6 z-50"
          onClick={() => setMobileMenu(false)} 
        />
        <nav className="flex flex-col items-center">
          <NavLink
            className="flex flex-row h-40 w-1/8 p-0 mx-6 justify-center items-center hover:cursor-pointer"
            to="/"
          >
            <img
              className="object-cover w-40 m-0 p-0 mb-8"
              src={Logo}
              alt="California Fitness Center Gym Logo"
              onClick={() => setMobileMenu(false)}
            />
          </NavLink>
          {paths.map((e, index) => (
            <NavLink
              key={e.pathway}
              to={e.link}
              className="text-white text-2xl py-2 active:text-primaryYellow"
              onClick={() => setMobileMenu(false)}
            >
              {e.pathway}
            </NavLink>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default NavBar;
