import React from "react";
import GymBanner from "../../assets/images/ig-showcase.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const IgShowcase = () => {
  const [t] = useTranslation("global")
  return (
    <div className="flex h-96 justify-center items-center flex-col mt-20 mb-20 lg:mt-12 lg:mb-12">
      <div
        className="w-full h-screen  relative bg-opacity-0"
        style={{
          backgroundImage: `linear-gradient(rgba(10, 10, 10, .45), rgba(10, 10, 10, .85)), url(${GymBanner})`,
          backgroundPosition: "0 0, 50% 85%",
          backgroundRepeat: "repeat, no-repeat",
          backgroundSize: "auto, cover",
          backgroundAttachment: "scroll, fixed",
        }}
      >
        <div className="w-full h-full  flex flex-col justify-center space-y-6 items-center backdrop-brightness-50">
          <FontAwesomeIcon
            icon={faInstagram}
            className="text-white text-6xl pt-20"
          />
          <div className="w-[2px] h-20 bg-primaryYellow block"></div>
          <span className="text-white text-4xl w-1/2 text-center">
            {t("IgShowcase.text")} 
          </span>
          <span className="text-white text-4xl text-center">
          @californiafitnescenter
          </span>
          <div className="pb-16 pt-12">
            <Link
              style={{ fontFamily: "Comfortaa" }}
              to="https://www.instagram.com/californiafitnescenter/"
              className="inline-block hover:bg-primaryYellow hover:text-black font-bold border-primaryYellow border-[1px] rounded-2xl p-4 mt-6 w-44 text-white hover:shadow-md  transition duration-300 text-center"
            >
              {t("IgShowcase.buttonText")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IgShowcase;
