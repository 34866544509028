import React, { useState, useEffect, Fragment } from "react";
import Card from "../cards-section/card";
import Handshake from "../../assets/icons/handshake.png";
import Bicycle from "../../assets/icons/bicycle.png";
import Gymnastics from "../../assets/icons/gymnastic-rings.png";
import BgBanner from "../../assets/images/california-2.jpeg";
import BgBanner1 from "../../assets/images/california-3.jpeg";
import BgBanner2 from "../../assets/images/california-4.jpeg";
import BgBanner3 from "../../assets/images/california-5.jpeg";
import NumberFlipper from "../NumberFlipper/numberFlipper";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";

const CardSection = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0.2, // Trigger when 70% of the component is visible
  });

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [inView]); // Run only when 'inView' changes

  const [t, i18next] = useTranslation("global");

  return (
    <div
      className="w-full h-auto lg:h-screen  relative bg-opacity-0 box-border"
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(10, 10, 10, .85) 50%, rgba(0, 0, 0, 1)), url(${BgBanner})`,
        backgroundPosition: "0 0, 50% 85%",
        backgroundRepeat: "repeat, no-repeat",
        backgroundSize: "auto, contain",
        backgroundAttachment: "scroll, fixed",
      }}
    >
      <div className="max-w-[1280px] mx-auto h-96 lg:h-44 pt-36 -pb-24">
        <h1 className="text-center w-64 mx-auto py-8 bg-black text-2xl px-4 border-2 border-primaryYellow rounded-xl ">
          {t("CardsSection.title")}
        </h1>
      </div>
      <div
        ref={ref}
        className="md:flex md:flex-row flex flex-col items-center justify-between h-full max-w-[1120px] mx-auto -mt-16"
      >
        <div
          className={`duration-[1400ms] ${
            isVisible
              ? "opacity-100 translate-x-0"
              : "opacity-0 lg:-translate-x-full"
          }`}
        >
          <Card
            backgroundImage={BgBanner1}
            icon={Bicycle}
            title={
              <div className="flex items-center">
                <div className="flex-none inline-block">
                  <NumberFlipper n={1200} />
                </div>
                <div className="flex-none inline-block  mt-4">
                  m<sup>2</sup>
                </div>
              </div>
            }
            text={t("CardsSection.area")}
            button_txt={t("CardsSection.area")}
          />
        </div>
        <div
          className={`duration-[1000ms] ${
            isVisible
              ? "opacity-100 translate-x-0 delay-400"
              : "opacity-0 lg:-translate-x-full"
          }`}
        >
          <Card
            backgroundImage={BgBanner2}
            icon={Handshake}
            title={<NumberFlipper n={6} />}
            text={t("CardsSection.text2")}
            button_txt={t("CardsSection.pts")}
          />
        </div>
        <div
          className={`duration-[600ms] ${
            isVisible
              ? "opacity-100 translate-x-0"
              : "opacity-0 lg:-translate-x-full"
          }`}
        >
          <Card
            backgroundImage={BgBanner3}
            icon={Gymnastics}
            title={<NumberFlipper n={6} />}
            text={t("CardsSection.text3")}
            button_txt={t("CardsSection.categories")}
          />
        </div>
      </div>
    </div>
  );
};
export default CardSection;

// https://vimeo.com/412102529
