import NavBar from "./components/NavBar/NavBar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage";
import AboutPage from "./pages/AboutPage/AboutPage";
import ContactPage from "./pages/ContactPage/ContactPage";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import OurGallery from "./pages/OurGallery/OurGallery";
import BackToTopButton from "./components/BackToTopBtn/BackToTopBtn";
import Footer from "./components/Footer/Footer";

const App = () => {
  document.title = "California Fitness Center"; // shtohet logjika më vonë

  return (
    <div className="App">
      <Router>
      <div className="absolute top-0 left-0 w-full z-50"><NavBar /></div>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="*" element={<OurGallery />} />
        </Routes>
        <Footer/>
        <BackToTopButton/>
      </Router>
    </div>
  );
};

export default App;
